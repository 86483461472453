<template>
  <div class="headers" :class="selectTheme()">
    <div class="left">
      <Icon name="arrow-left" v-if="showLeft" @click="back()"></Icon>
    </div>
    <div class="middle">{{title}}</div>
    <div class="right" >
      <div v-if="!!rightText" @click="clickRight()">
        {{rightText}}
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from 'vant';

export default {
  name: "headers",
  components: {
    Icon
  },
  props:{
    title: {
      type: String,
      default: ''
    },
    // 是否显示返回按钮
    showLeft: {
      type: Boolean,
      default: true
    },
    showRight: {
      type: Boolean,
      default: true
    },
    rightText: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.activeIndex = this.$store.getters['getThemeIndex']
  },
  data() {
    return {
      activeIndex: 0,
    }
  },
  methods: {
    back(){
      this.$router.back()
    },
    clickRight(){
      this.$emit('clickRight')
    },
    selectTheme(){
      switch (Number(this.activeIndex)) {
        case 0:
         return 'headerBgWhite';
        case 1:
          return 'headerBgRed';
        case 2:
          return 'headerBgBlack';
        case 3:
          return 'headerBgBlue';

      }
    }
  }
}
</script>

<style lang="scss">
.headers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 20px !important;
  background: #fff;
  .left {
    font-size: 22px;
    display: flex;
    align-items: center;
  }
  .middle {
    font-size: 18px;
    width: 65%;
    font-weight: bold;
  }
  .right {
    font-size: 14px;
  }
}
</style>
<template>
<div class="leaderboard page">
  <headers title="排行榜"></headers>
  <div class="scroll">
    <div class="firstRow">
      <div class="first" v-for="(item,index) in list1" :key="index" :class="'first'+(index+1)">
        <div class="userInfo">
          <img :src="item.photo" class="avatar" alt="">
          <p>{{item.truename}}</p>
        </div>
        <div class="trophy">
          <img :src="item.trophy_img" alt="">
        </div>
        <div class="commission" :style="'background-image: url('+item.money_bg+')'">
          <div class="money">{{item.order_yj}}</div>
          <p>佣金金额</p>
        </div>
      </div>
    </div>
    <ul class="list">
      <li v-for="(item,index) in list" :key="'list'+index">
          <div class="left">{{index+4}}</div>
          <div class="right flexCenter">
            <img class="avatar" :src="item.photo" alt="">
            <div class="info">
              <div class="name">{{item.truename}}</div>
              <p>佣金金额: <span class="money">{{item.order_yj}}元</span></p>
            </div>
          </div>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
import headers from "@/components/headers.vue";
import {paihangbang} from "@/axios/api";
export default {
  name: "leaderboard",
  components: {
    headers
  },
  data() {
    return {
      list1: [],
      list: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList(){
      $api.paihangbang({
        token: this.$store.getters["getToken"]
      }).then(res => {
        // eslint-disable-next-line no-irregular-whitespace
          this.list1.push({
            ...res.data.two[0],
            trophy_img: require('/static/img/jiang1.png'),
            money_bg: require('/static/img/pai1.png'),
          });
        // eslint-disable-next-line no-irregular-whitespace
      　　this.list1.push({
            ...res.data.one[0],
            trophy_img: require('/static/img/jiang2.png'),
            money_bg: require('/static/img/pai2.png'),
          })

          this.list1.push({
            ...res.data.three[0],
            trophy_img: require('/static/img/jiang3.png'),
            money_bg: require('/static/img/pai3.png'),
          })

          this.list = res.data.four
      })
    }
  }
}
</script>

<style scoped lang="scss">
.leaderboard {
  background: #f7f7fe;
  .firstRow {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 15px;
    width: 100%;
    background: #fff;
    .first {
      width: 33%;
      .userInfo {
        font-size: 14px;
        .avatar {
          width: 38px;
          height: 38px;
        }
      }
      .trophy {
        width: 37px;
        height: 47px;
        margin: 15px auto 0;
        position: relative;
        z-index: 2;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .commission {
        width: 100%;
        height: 75px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-top: 10px;
        .money {
          color: #4D6CFF;
          font-weight: bold;
          font-size: 20px;
          height: 53px;
          line-height: 78px;
        }
      }
    }
    .first2 {
      .trophy {
        margin-top: 22px;
      }
      .commission {
        height: 100px;
        margin-top: -10px;
         .money {
           color: #ff4949;
           height: 70px;
           line-height: 95px;
           font-size: 22px;
         }
      }
    }
    .first3 {
      .commission {
        .money {
          color: #e69434;
        }
      }
    }

  }
  .list {
    margin: 15px;
    background: #fff;
    overflow: hidden;
    border-radius: 10px;
    padding: 15px 0;
    li {
      display: flex;
      align-items: center;
      padding: 15px 15px;
      .left {
        font-size: 16px;
        margin-right: 20px;
      }
      .right {
        text-align: left;
        font-size: 13px;
        color: #999;
        .avatar {
          width: 38px;
          height: 38px;
          margin-right: 10px;
        }
        .name {
          font-size: 16px;
          margin-bottom: 10px;
          color: #000;
        }
        .money {
          color: #ff4949;
        }
      }
    }
  }
}
</style>